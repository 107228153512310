// Basic Colors
$color-black: #000;
$color-white: #fff;
$color-anthrazit: #555;
$color-dark-gray: #333;

// Main Colors
$color-bordeaux: #009934;
$color-bordeaux-200: #CCE6D6;
$color-petrol: #009934;
$color-petrol-100: #CCE6D6;
$color-petrol-200: #CCE6D6;
$color-yellow: #009934;
$color-yellow-200: #CCE6D6;

// Accent Colors
$color-olive-green: #009934;
$color-olive-green-200: #CCE6D6;
$color-blue: #009934;
$color-blue-200: #CCE6D6;
$color-orange-red: #009934;
$color-orange-red-200: #CCE6D6;

// Alert Colors
$color-alert-success: $color-olive-green;
$color-alert-info: $color-blue;
$color-alert-error: $color-orange-red;
$color-alert-warning: $color-yellow;

// Text Colors
$color-text-copy: #101010;
$color-text-copy-light: #aaa;
$color-text-destructive: $color-orange-red;

// Border Colors
$color-border-light-gray: rgba( 0, 0, 0, .1 );

// Color map for iterating over colors
$color-map: (
	bordeaux: $color-bordeaux,
	petrol: $color-petrol,
	yellow: $color-yellow,
	olive-green: $color-olive-green,
	blue: $color-blue,
	orange-red: $color-orange-red,
);
