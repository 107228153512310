@import "styles/shared";

.error-boundary {
	min-height: 100%;
	display: flex;
	padding: 1.125rem;

	&__content {
		background: $color-white;
		color: $color-text-copy;
		box-shadow: 0 1px 3px 0 rgba( 0, 0, 0, .4 );
		border-radius: 0;
		padding: 2rem;
		margin: auto;
	}

	&__stack {
		position: absolute;
		left: -1000px;
		top: -1000px;
	}

	h1,
	a {
		color: $color-petrol;

		.page-theme--bordeaux & {
			color: $color-bordeaux;
		}

		.page-theme--yellow & {
			color: $color-yellow;
		}

		.page-theme--olive-green & {
			color: $color-olive-green;
		}

		.page-theme--blue & {
			color: $color-blue;
		}

		.page-theme--orange-red & {
			color: $color-orange-red;
		}

		.page-theme--anthrazit & {
			color: $color-anthrazit;
		}
	}
}
