@import "styles/shared";

@font-face {
	font-family: "Helvetica Neue LT W05_45 Light";
	font-weight: 400;
	font-display: swap;
	src:
		url( fonts/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2 ) format( "woff2" ),
		url( fonts/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff ) format( "woff" );
}

@font-face {
	font-family: "Helvetica Neue LT W05_45 Light Fallback";
	src: local( "Arial" );
	ascent-override: 99.55%;
	descent-override: 22.03%;
	line-gap-override: 0%;
	size-adjust: 97.14%;
}

@font-face {
	font-family: "Helvetica Neue LT W05_46 Lt It";
	font-weight: 400;
	font-display: swap;
	src:
		url( fonts/5e4f385b-17ff-4d27-a63a-9ee28546c9a8.woff2 ) format( "woff2" ),
		url( fonts/116cde47-4a07-44a5-9fac-cbdcc1f14f79.woff ) format( "woff" );
}

@font-face {
	font-family: "Helvetica Neue LT W05_55 Roman";
	font-weight: 500;
	font-display: swap;
	src:
		url( fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2 ) format( "woff2" ),
		url( fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff ) format( "woff" );
}

@font-face {
	font-family: "Helvetica Neue LT W05_55 Roman Fallback";
	src: local( "Arial" );
	ascent-override: 95.98%;
	descent-override: 21.11%;
	line-gap-override: 0%;
	size-adjust: 101.37%;
}

@font-face {
	font-family: "Helvetica Neue LT W05_56 Italic";
	font-weight: 500;
	font-display: swap;
	src:
		url( fonts/4bd56f95-e7ab-4a32-91fd-b8704cbd38bc.woff2 ) format( "woff2" ),
		url( fonts/4fe1c328-1f21-434a-8f0d-5e0cf6c70dfb.woff ) format( "woff" ),;
}

@font-face {
	font-family: "Helvetica Neue LT W05_65 Medium";
	font-weight: 600;
	font-display: swap;
	src:
		url( fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2 ) format( "woff2" ),
		url( fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff ) format( "woff" );
}

@font-face {
	font-family: "Helvetica Neue LT W05_65 Medium Fallback";
	src: local( "Arial" );
	ascent-override: 91.23%;
	descent-override: 21.26%;
	line-gap-override: 0%;
	size-adjust: 104.9%;
}

@font-face {
	font-family: "Helvetica Neue LT W05_75 Bold";
	font-weight: 700;
	font-display: swap;
	src:
		url( fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2 ) format( "woff2" ),
		url( fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff ) format( "woff" );
}

@font-face {
	font-family: "Helvetica Neue LT W05_75 Bold Fallback";
	src: local( "Arial" );
	ascent-override: 89.89%;
	descent-override: 20.05%;
	line-gap-override: 0%;
	size-adjust: 108.25%;
}

@font-face {
	font-family: "Helvetica Neue LT W05_85 Heavy";
	font-weight: 800;
	font-display: swap;
	src:
		url( fonts/7e42a406-9133-48c0-a705-4264ac520b43.woff2 ) format( "woff2" ),
		url( fonts/837750e9-3227-455d-a04e-dc76764aefcf.woff ) format( "woff" );
}

@font-face {
	font-family: "Helvetica Neue LT W05_85 Heavy Fallback";
	src: local( "Arial" );
	ascent-override: 83.31%;
	descent-override: 20.52%;
	line-gap-override: 0%;
	size-adjust: 114.04%;
}

@font-face {
	font-family: "Helvetica Neue LT W05_95 Black";
	font-weight: 900;
	font-display: swap;
	src:
		url( fonts/fc4fb6ca-f981-4115-b882-c78e9f08be52.woff2 ) format( "woff2" ),
		url( fonts/6ed03453-f512-45ba-84bf-fe4ea45d5e6a.woff ) format( "woff" );
}

@font-face {
	font-family: "Helvetica Neue LT W05_95 Black Fallback";
	src: local( "Arial" );
	ascent-override: 81.07%;
	descent-override: 19.7%;
	line-gap-override: 0%;
	size-adjust: 118.29%;
}

@font-face {
	font-family: "Helvetica Neue LT W05_96 Blk It";
	font-display: swap;
	src:
		url( fonts/995add04-59cc-41ea-abd2-4712eaddf2a8.woff2 ) format( "woff2" ),
		url( fonts/7090e465-f6bf-4664-8b5a-d877a6915d87.woff ) format( "woff" );
}

html,
body,
#root {
	width: 100%;
	height: 100%;
}

html {
	box-sizing: border-box;
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-text-size-adjust: 100%;

	@media ( prefers-reduced-motion: no-preference ) {
		scroll-behavior: smooth;
	}
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	font-size: $base-font-size;
	line-height: $base-line-height;
	margin: 0;
	padding: 0;
	font-family: $main-font-family;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #ebebe6; // S#ebebe6u
	color: $color-text-copy;
	transition: background 240ms ease-in-out;
}

.page-theme--bordeaux {
	background-color: #ebebe6;
}

.page-theme--yellow {
	background-color: #ebebe6;
}

.page-theme--olive-green {
	background-color: #ebebe6;
}

.page-theme--blue {
	background-color: #ebebe6;
}

.page-theme--orange-red {
	background-color: #ebebe6;
}

.page-theme--anthrazit {
	background-color: #ebebe6;
}

.app {
	min-height: 100%;
	display: grid;
	grid-template-rows: auto auto 1fr auto;
	grid-template-columns: 100%;
}

.app-content {
	order: 2;
	padding-right: env( safe-area-inset-right );
	padding-left: env( safe-area-inset-left );
}

.app-content__inner {
	@include content-inner;
	height: 100%;
	padding-top: .9375rem;
	padding-bottom: .9375rem;

	@media screen and ( min-width: 800px ) {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}

	@media screen and ( min-width: 1000px ) {
		padding-top: 1.875rem;
		padding-bottom: 1.875rem;
	}
}

h1 {
	font-family: $black-font-family;
	margin: 0 0 .5em 0;
	font-size: calc( 1.25rem + 2vw );
	line-height: 1.25;

	@media only screen and ( min-width: 1000px ) {
		font-size: 2.5rem;
	}
}

h2 {
	font-family: $black-font-family;
	color: $color-petrol;
	font-size: calc( 1.125rem + .75vw );
	line-height: 1.25;
	margin: 2em 0 1em;

	@media only screen and ( min-width: 1000px ) {
		font-size: 1.5625rem;
	}

	.page-theme--bordeaux & {
		color: $color-bordeaux;
	}

	.page-theme--yellow & {
		color: $color-yellow;
	}

	.page-theme--olive-green & {
		color: $color-olive-green;
	}

	.page-theme--blue & {
		color: $color-blue;
	}

	.page-theme--orange-red & {
		color: $color-orange-red;
	}

	.page-theme--anthrazit & {
		color: $color-anthrazit;
	}
}

h3 {
	font-family: $black-font-family;
	line-height: 1.25;
	margin: 1.5em 0 .5em;
}

h4 {
	font-family: $black-font-family;
}

fieldset {
	border: none;
	padding: 0;
	margin: 0 0 1.25em 0;
}

legend {
	display: block;
	margin-bottom: .2rem;
	padding: 0;
	color: tint( $color-anthrazit, 15% );
	font-family: $bold-font-family;
	font-weight: 600;
	line-height: 1.5;
}

figure {
	margin: 0;
}

p {
	margin: 0 0 1.25em 0;
}

ul,
ol {
	margin: 0 0 1.25em 1.5625em;
	padding: 0;

	ul,
	ol {
		margin-bottom: 0;
	}
}

ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

strong,
b,
legend {
	font-family: $bold-font-family;
}

hr:not(.wp-block-separator) {
	margin: 2.5rem 0;
	border: none;
	height: 2px;
	background: rgba( 0, 0, 0, .1 );
}

del {
	background: none;
	text-decoration: line-through;
}

a {
	outline: none;
	color: $color-petrol;

	.page-theme--bordeaux & {
		color: $color-bordeaux;
	}

	.page-theme--yellow & {
		color: $color-yellow;
	}

	.page-theme--olive-green & {
		color: $color-olive-green;
	}

	.page-theme--blue & {
		color: $color-blue;
	}

	.page-theme--orange-red & {
		color: $color-orange-red;
	}

	.page-theme--anthrazit & {
		color: $color-anthrazit;
	}

	&:hover,
	&:focus {
		color: $color-text-copy;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $color-text-copy;
		}
	}
}

video,
iframe {
	display: block;
	max-width: 100%;
}

@media print {
	.app-header__navigation,
	.page__back-link,
	.content-anchors,
	.page__content-filter-bar,
	.app-header .mobile-search-open,
	.app-header .mobile-menu-open,
	.page__footer,
	.button,
	.react-tabs__tab-list {
		display: none !important;
	}

	body {
		font-size: 8pt;
		padding: 0 .5cm;
		color: $color-black !important;
		background-color: #ebebe6;
	}

	.app-header {
		padding: 0 0 .5cm 0 !important;
		box-shadow: none !important;

		.app-header__logo {
			margin: 0;
			max-height: 48px;
		}
	}

	.page__header {
		color: $color-black !important;
	}

	.app-header__inner,
	.app-content__inner,
	.page__content-primary,
	.page__content-secondary,
	.app-footer__colophon-inner {
		padding-left: 0 !important;
		padding-right: 0 !important;
		color: $color-black !important;
	}

	.app-content__inner {
		.profession-media {
			display: none;
		}
	}

	h1 {
		font-size: 13pt !important;
		line-height: 1;
		margin-bottom: .25em;
		color: $color-black !important;
	}

	h2 {
		font-size: 10pt !important;
		line-height: 1;
		margin-top: .5em;
		margin-bottom: .25em;
		color: $color-black !important;
		border-top: .1pt solid $color-border-light-gray;
		padding-top: 1em;
	}

	h3 {
		font-size: 8pt !important;
		line-height: 1;
		margin-top: .5em;
		margin-bottom: .25em;
		color: $color-black !important;
	}

	h4 {
		font-size: 8pt !important;
		line-height: 1;
		margin-top: .5em;
		margin-bottom: .25em;
	}

	h5 {
		font-size: 9pt;
		line-height: 1;
		margin-top: .5em;
		margin-bottom: .25em;
	}

	h6 {
		font-size: 8pt;
		line-height: 1;
		margin-top: .5em;
		margin-bottom: .25em;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		page-break-after: avoid;
	}

	p {
		line-height: 1.2;
		margin-bottom: .5em;
	}

	a {
		color: $color-petrol !important;
	}

	dd,
	dt {
		font-size: 8pt !important;
		line-height: 1.3;

		em {
			font-size: 8pt !important;
		}

		&::before {
			margin: 0 !important;
		}
	}

	dl {
		margin: .5em 0;
	}

	table,
	figure {
		page-break-inside: avoid;
	}

	.app-footer {
		border-top: .1pt solid rgba( 0, 0, 0, .1 );
	}

	.app-footer__colophon {
		box-shadow: none;
	}

	.app-footer__colophon a {
		display: none;
	}

	.app-version {
		display: none !important;
	}
}

// Hack for changing colors
:root, body, main {
	--wp--preset--color--petrol: #009934;
}
